import { graphql, useStaticQuery } from "gatsby"

import { ProductModel } from "../model/productModel"

const useProducts = (isProductList = false) => {
  return useStaticQuery(graphql`
    query AllProductsQuery {
      allStrapiProduct(filter: { show_on_website: { eq: true } }) {
        edges {
          node {
            show_in_productlist
            ...ProductParamsFragment
          }
        }
      }
    }
  `)
    .allStrapiProduct.edges.filter(
      ({ node }) => !isProductList || node.show_in_productlist,
    )
    .map(({ node }) => new ProductModel(node))
    .sort((p1, p2) => p1.name.localeCompare(p2.name))
}

export default useProducts
