import { withFormElement } from "@brynlabs/webforms-react"
import PropTypes from "prop-types"
import React from "react"

import useMarkets from "../../../hooks/useMarkets"
import { useTranslation } from "../../../i18n/translate"
import Select from "../select/select"

const MarketSelect = ({
  onSelect,
  value,
  valueAsString,
  required,
  ...props
}) => {
  const { t } = useTranslation()
  const markets = useMarkets().sort((a, b) =>
    t(a.name.key).localeCompare(t(b.name.key)),
  )

  return (
    <Select
      required={required}
      values={markets}
      value={value}
      valueAsString={valueAsString}
      mapValueToString={(market) => t(market.name.key)}
      mapValueForValueAsString={(market) => t(market.name.key, { lng: "en" })}
      onSelect={onSelect}
      {...props}
    />
  )
}

const WebformsMarketSelect = withFormElement(
  ({ setData, getData, ...props }) => {
    const { t } = useTranslation()

    return (
      <>
        <MarketSelect
          onSelect={(market) => {
            setData(market ? t(market?.name?.key, { lng: "en" }) : undefined)
          }}
          valueAsString={getData()}
          {...props}
        />
      </>
    )
  },
)

MarketSelect.propTypes = {
  defaultText: PropTypes.string,
  darkAppearance: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.any,
  titleTranslatedString: PropTypes.string,
  required: PropTypes.bool,
}

export { MarketSelect, WebformsMarketSelect }
